import { Fetch, FetchFn, FetchResponse, IFetchApi } from '@wix/thunderbolt-symbols'
import { toFormData } from '../../lib/toFormData'
import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { Environment } from '../../types/Environment'
import { NoopCache, withCache } from './withCache'
import { Cache } from '@wix/thunderbolt-ssr-api'

export function FetchApi(requestUrl: string, fetchFn: FetchFn, cache: Cache<string, string> = NoopCache()): IFetchApi {
	function envFetch(url: string, init?: RequestInit) {
		return fetchFn(url, init)
	}

	const cachedFetch = withCache(envFetch, cache)

	return {
		getJson: <T>(url: string): Promise<T> => {
			const options = { headers: { referer: requestUrl } }
			return envFetch(url, options).then((x) => x.json())
		},
		postFormData(url: string, formData: any) {
			const data = toFormData(formData)
			return envFetch(url, {
				method: 'POST',
				body: data,
			}).then((x) => x.json())
		},
		envFetch,
		async getWithCacheInSsr(url: string): Promise<FetchResponse> {
			return cachedFetch(url, { headers: { referer: requestUrl } })
		},
	}
}

export const site = ({ fetchApi }: Environment): ContainerModuleLoader => (bind) => {
	bind(Fetch).toConstantValue(fetchApi)
}
